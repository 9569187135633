import { CacheContext } from "components/contexts/CacheContext";
import { Button } from "components/form/Button";
import React, { useContext, useEffect, useState } from "react";
import { StoreShippingRestrictions } from "./StoreShippingRestrictions";
import useStoreDelivery from "components/hooks/useStoreDelivery";
import useUserAddresses from "components/hooks/useUserAddresses";
import { RichSelect } from "components/form/RichSelect";
import { AuthContext } from "components/contexts/AuthContext";

export const SingleStoreDelivery = ({
	store,
	cart,
	singleStoreView = true,
	setDeliveryPrice = () => {},
	onCityChangeCallback = () => {},
}) => {
	const cache = useContext(CacheContext);
	const { claims } = useContext(AuthContext);
	const [deliveryData, setDeliveryData] = useState(null);

	const [deliveryCity, setDeliveryCity] = useState(
		Number(claims?.delivery_city?.id) || null,
	);
	const {
		getDeliveryInfoByCityId,
		getCartDeliveryInfoByCityId,
		loading,
		checkIfConditionMet,
	} = useStoreDelivery(store.id);

	const { userAddresses } = useUserAddresses();

	const onCityChange = async value => {
		let data;

		if (value === deliveryCity && !!deliveryData) {
			const eligibleTiers = checkIfConditionMet(deliveryData.priceTiers, cart);
			data = { ...deliveryData, eligibleTiers: eligibleTiers };
		} else {
			setDeliveryCity(value);
			if (!!cart) {
				data = await getCartDeliveryInfoByCityId(value, cart);
			} else {
				data = await getDeliveryInfoByCityId(value);
			}
		}
		setDeliveryPrice(data?.eligibleTiers?.[0]?.shipping_cost ?? null);
		setDeliveryData(data);
		onCityChangeCallback(value);
	};

	const formattedAddresses = userAddresses.map(address => ({
		label: address.city_heb_name,
		value: address.city_id,
		isHomeAddress: true,
	}));

	const formattedAllCities = cache.allCities.map(c => ({
		label: c.heb_name,
		value: c.id,
	}));

	const options = [...formattedAddresses, ...formattedAllCities].filter(
		(value, index, self) =>
			index ===
			self.findIndex(t => t.label === value.label && t.value === value.value),
	);

	useEffect(() => {
		if (!!deliveryCity) {
			onCityChange(deliveryCity);
		}
	}, [cart?.totalPrice]);

	return (
		<div className="flex flex-col transition-all">
			<div className="w-full mb-2">
				<label className="font-semibold">שולחים אלי?</label>
				<RichSelect
					className="w-full h-[34px]"
					controlShouldRenderValue
					isSearchableFilter={true}
					placeholder="עיר/ישוב"
					options={options}
					value={deliveryCity}
					onChange={onCityChange}
					formatOptionLabel={option => {
						if (option.isHomeAddress) {
							return <span className="font-bold">{option.label}</span>;
						} else {
							return option.label;
						}
					}}
				/>
			</div>
			<StoreShippingRestrictions
				deliveryData={deliveryData}
				singleStoreView={singleStoreView}
				loading={loading}
			/>
		</div>
	);
};
